.navbar {
  background-color: var(--footer-color);
  display: none;
  justify-content: flex-end;
  color: var(--primary-color);
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  transition: color 0.3s ease;
}

.normalNavbar {
  background-color: var(--footer-color);
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  border: solid #383838 5px;
  align-items: center;
  list-style-type: none;
  text-decoration: none;
  transition: color 0.3s ease;
}

.button {
  background-color: var(--footer-color);
  border: none;
  padding: 15px 32px;
  text-align: center;
  font-size: 1em;
  display: flex;
}

.iconText {
  margin-left: 0.5rem;
  color: var(--primary-color);
  text-decoration: none;
}

.linkTo {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease;
}

.iconText:hover {
  color: var(--secondary-color);
  text-shadow: var(--secondary-color) 1px 0 5px;
  cursor: pointer;
}

.customIcon {
  color: var(--primary-color);
}


.hamburgerButton {
  display: none; /* Standardmäßig ausblenden */
}

.dropdownContent {
  display: none; /* Standardmäßig ausblenden */
}

.showDropdown {
  display: block; /* Dropdown-Menü anzeigen, wenn showDropdown true ist */
}


@media screen and (max-width: 800px) {
  .hamburgerButton {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: transparent; /* Hintergrundfarbe transparent */
    border: none; /* Kein Rand */
    cursor: pointer; /* Zeige den Zeiger als Zeigegerät an */
  }
  
  .customIcon {
    color: var(--primary-color); /* Farbe des Icons */
    font-size: 200%; /* Größe des Icons */
  }
  

  .navbar {
    display: flex; /* Dropdown-Menü anzeigen, wenn showDropdown true ist */
    flex-direction: column; /* Elemente untereinander anzeigen */
    justify-content:start; /* Horizontal zentrieren */
    text-align: center;
  }

  .button {
    display: flex;
    margin: 2%; /* Abstand zwischen den Buttons */
    width: 70%;
    height: auto;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
  }

  .iconText {
    margin-left: 0px;
    margin-top: 3%;
  }

  .linkTo {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .normalNavbar {
    display: none;
  }

  .dropdownContent {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8); /* Hier wird die Transparenz festgelegt (0.5 entspricht 50% und kann angepasst werden) */
    color: var(--primary-color);
    transition: color 0.2s ease;
    z-index: 4;
    border-radius: 10px;
  }
  

  .showDropdown {
    width: 50%;
  }
}
