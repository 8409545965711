/* Kontakt.module.css */

.title {
  text-align: center;
  margin-bottom: 20px;
}

.contactInfo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.contactItem {
  width: 300px;
  margin: 0 20px 20px 20px;
  background-color: #b4b4b4;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contactItem h3 {
  color: #333;
  margin-bottom: 10px;
  color: black;
}

.contactItem p {
  margin: 0;
  color: black;
}

.mapContainer {
  display: flex;
  width: 90%;
  height: 500px;
  justify-content: center;
}

/* Media queries */
@media screen and (max-width: 768px) {
  .contactItem p{
    width: 90%; /* Adjust width for smaller screens */
    margin: 0 auto 20px auto; /* Center-align and add margin */
  }
}

@media screen and (max-width: 500px) {
  .contactItem p{
    width: 90%; /* Adjust width for even smaller screens */
  }

  .mapContainer {
    width: 90%; /* Adjust map container width for smaller screens */
    height: 250px;
    justify-content: center;
  }
}