.footer {
  background-color: var(--footer-color);
  color: #fff; /* Textfarbe im Footer */
  padding: 20px; /* Innenabstand des Footers */
  text-align: center; /* Zentrierung des Textes im Footer */
}

.navList {
  list-style: none; /* Entfernt die standardmäßige Listenformatierung */
  padding: 0; /* Entfernt den Innenabstand der Liste */
}

.navItem {
  display: inline-block; /* Anzeigen der Listenelemente nebeneinander */
  margin-right: 20px; /* Abstand zwischen den Listenelementen */
}

.navLink {
  color: #fff; /* Textfarbe der Links */
  text-decoration: none; /* Entfernt die Standard-Unterstreichung der Links */
  transition: color 0.3s ease; /* Animiert die Farbänderung beim Hovern über den Links */
}

.navLink:hover {
  color: #00b31e; /* Farbe der Links beim Hovern */
}

.footerText {
  margin-top: 20px; /* Abstand zum oberen Text */
}

@media screen and (max-width: 768px) {
  .navItem {
    display: block; /* Ändert die Anzeige der Listenelemente auf Block, um sie gestapelt anzuzeigen */
    margin-right: 0; /* Entfernt den rechten Abstand zwischen den gestapelten Listenelementen */
    margin-bottom: 10px; /* Fügt einen Abstand zwischen den gestapelten Listenelementen hinzu */
  }
}
