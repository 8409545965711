@font-face {
  font-family: 'trebuc';
  src: url('/public/fonts/trebuc.ttf');
}
body {
  margin: 0;
  font-family: 'trebuc';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--primary-color);
}

p{
  color: var(--secondary-color);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
