/* Home.module.css */

.container {
  text-align: center;
  padding: 50px;
}

.container p {
  color:#b3b3b3;
}

.heading {
  font-size: 7vh;
  margin-bottom: 20px;
  color: #007c15; /* Blaue Schriftfarbe */
}

.subtitle {
  font-size: 3vh;
  margin-bottom: 40px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 90px;
  margin-bottom: 40px;
}

.feature {
  flex: 1 1 300px;
  max-width: 300px;
}

.featureIcon {
  font-size: 48px;
  margin-bottom: 20px;
  color: #28a745; /* Grün für die Icons */
}

.featureTitle {
  font-size: 24px;
  margin-bottom: 10px;
  color: #00994d; /* Schwarze Schriftfarbe */
}

.featureDescription {
  font-size: 16px;
  color: #cecece; /* Dunkelgraue Schriftfarbe */
}

.ctaButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
}

.ctaButton:hover {
  background-color: #00b31e;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 30px;
  }

  .heading {
    font-size: 5vh;
  }

  .subtitle {
    font-size: 2vh;
  }

  .features {
    gap: 40px;
  }
}