.impressum {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.ueberschrift {
  color: #ffffff;
  text-shadow: rgb(0, 255, 42) 0px 0 10px;
  text-align: center;
  padding: 20px;
  margin-bottom: 1px;
  margin-top: 20px;
}

.impline {
  border: 1px solid rgba(40, 252, 2, 0);
  box-shadow: rgb(0, 255, 85) 0px 0 10px;
  border-radius: 5px;
  width: 40%;
  margin: 0 auto;
}

.section {
  flex: 1 1 300px; 
  margin: 20px;
  padding: 20px;
  background-color: rgba(85, 85, 85, 0.3);
  border: 0.5px solid rgba(43, 255, 0, 0.514);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 24px;
  color: #ffffff;
}

.section hr {
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #ddd;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.content h3 {
  font-size: 18px;
  color: #ffffff;
}

.content p {
  font-size: 16px;
  color: #ffffff;
}

.platform {
  margin-top: 40px;
}

.platform h2 {
  font-size: 24px;
  color: #686868;
  margin-bottom: 10px;
  text-align: center;
}

.platform p {
  font-size: 16px;
  color: #888;
  text-align: center;
}

.platform a {
  font-size: 16px;
  color: var(--secondary-color);
  padding-left: 5px;
}

.links a {
  color: inherit; /* Übernimmt die Farbe des umgebenden Elements */
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.links a:hover {
  text-decoration: underline; /* Fügt Unterstreichung hinzu, wenn der Link gehovered wird */
}


@media only screen and (max-width: 768px) {
  .impressum {
    width: 90%;
    margin: 0 auto; /* Horizontale Zentrierung */
  }

  .impline {
    width: 80%;
    margin: 0 auto; /* Horizontale Zentrierung */
  }

  .section {
    display: block;
    min-block-size: 100%; 
    margin: 0px;
    padding: 15px;
    width: 100%;
    margin: 10px;
  }

  .content {
    margin-top: 20px; /* Erhöhe den oberen Abstand zwischen den Inhalten */
    width: 80%;
    display: grid;
    grid-template-columns: 3fr;
    padding: 10px;
    margin: 0 auto; /* Horizontale Zentrierung */
  }

  .content h3 {
    text-align: left;
    width: 100%;
  }

  .section h2 {
    text-align: center;
  }
}
