/* Carousel.module.css */

.ueberschrift {
  text-align: center; /* Zentriert den Text */
  text-shadow: 1px 1px 1px chartreuse; /* Fügt einen grünen Schatten hinzu */
  font-size: 3vw; /* Textgröße relativ zur Bildschirmbreite */
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Mindestens 200px breit, maximale Breite basierend auf verfügbarem Platz */
  gap: 20px; /* Abstand zwischen den Bildern */
  padding: 40px; /* Erhöhte Polsterung für den Rand */
  max-width: 50%; /* Maximal 4 Bilder nebeneinander, 4 * (200px + 20px) */
  margin: 0 auto; /* Zentriert die Bilder auf der Seite */
}

.gridItem {
  position: relative;
  display: flex;
  justify-content: center; /* Thumbnails mittig ausrichten */
}

.thumbnail {
  width: 100%;
  height: 200px; /* Festgelegte Höhe für die Thumbnails */
  object-fit: cover; /* Die Bilder werden so skaliert, dass sie die Thumbnail-Fläche vollständig ausfüllen, und werden nicht verzerrt */
  cursor: pointer;
  border-radius: 10px; /* Runde Ecken für die Bilder */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Schatten für die Bilder */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparenter Hintergrund */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: rgb(133, 133, 133, 0.6);
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.modalImage {
  max-width: 90vw; /* Maximale Breite des Bildes */
  max-height: 90vh; /* Maximale Höhe des Bildes */
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.7); /* Schwarze Farbe mit leichter Transparenz */
  background-color: rgba(5, 5, 5, 0.9); /* Weiße Hintergrundfarbe mit leichter Transparenz */
  border-radius: 10%; /* Runde Ecken für das Schließen-Symbol */
  padding: 5px; /* Zusätzlicher Innenabstand für das Symbol */
  transition: color 0.3s ease, background-color 0.3s ease; /* Animation für Farbänderungen */
}

.closeButton:hover {
  color: rgba(0, 0, 0, 0.9); /* Dunklere Schriftfarbe beim Hovern */
  background-color: rgba(255, 255, 255, 0.9); /* Hellerer Hintergrund beim Hovern */
}



@media screen and (max-width: 768px) {
  .ueberschrift {
    font-size: 5vw; /* Textgröße relativ zur Bildschirmbreite */
  }

  .gridContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Mindestens 150px breit, maximale Breite basierend auf verfügbarem Platz */
    padding: 20px; /* Reduzierte Polsterung für den Rand */
  }

  .thumbnail {
    height: 150px; /* Angepasste Höhe für die Thumbnails */
    width: 150px;
  }

  .gridContainer {
    max-width: 100%; /* Maximal 4 Bilder nebeneinander, 4 * (200px + 20px) */
  }
}
