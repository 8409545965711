.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2%;
    padding-top: 2%;
    text-align: center;
    z-index: 1; 
}

.logoSize {
    width: 30%;
    animation: glow 0.9s ease-in-out infinite alternate;
}

@keyframes glow {
    from {
        filter: brightness(110%);
    }
    to {
        filter: brightness(150%);
    }
}

.container-fluid {
    display: block;
    width: 100%;
    background-color: var(--footer-color);
    color: var(--primary-color);
    text-align: center;
    position: relative;
}

@media only screen and (max-width: 768px) {
    /* Styles for screens up to 768px wide */
    .logoSize {
        width: 70%; /* Adjust size for smaller screens */
    }
}